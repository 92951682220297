<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        md="5"
        lg="8"
      >
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          Dosen
        </h2>
      </b-col>
      <b-col
        md="7"
        lg="4"
      >
        <!-- Search -->
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            placeholder="Cari Nama Dosen"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">
          Daftar Dosen
        </h4>
        <b-row
          align-v="right"
          align-h="between"
        >
          <b-col
            sm="12"
            class="d-flex justify-content-between justify-content-sm-end align-items-center"
          >
            <b-button
              v-b-modal.new-data
              variant="outline-primary"
              class="action-button mr-1"
            >
              <feather-icon icon="PlusIcon" />
              <span>Tambah</span>
            </b-button>
            <b-button
              v-b-modal.new-data-excel
              variant="warning"
              class="action-button mr-1"
            >
              <feather-icon icon="DatabaseIcon" />
              <span> Impor Kerangka.xlsx</span>
            </b-button>
            <b-button
              variant="success"
              class="action-button mr-1"
              :href="`${BACKEND_URL_1}/TEMPLATE_TAMBAH_DOSEN.xlsx`"
            >
              <feather-icon icon="DownloadIcon" />
              <span> Unduh Kerangka.xlsx</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <!-- Body -->
      <overlay-loading :loading="fetchLoading">
        <b-table
          id="table-transition-gjmf"
          ref="usersTable"
          class="m-0"
          responsive="sm"
          show-empty
          primary-key="number"
          hover
          :tbody-transition-props="transProps"
          :fields="fields"
          :items="dosens"
        >
          <!-- No Data -->
          <template #empty>
            <p class="mb-0 py-2 text-center">
              Tidak ada data
            </p>
          </template>
          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <b-button
                v-b-modal.edit-data
                variant="success"
                class="action-button mr-1"
                @click="() => prepareUpdate(data)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-b-modal.delete-data
                variant="danger"
                class="action-button"
                @click="() => prepareDelete(data)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </overlay-loading>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Excel dosen modal -->
    <b-modal
      id="new-data-excel"
      title="Tambah Dosen"
      cancel-variant="secondary"
      ok-title="Simpan"
      cancel-title="Batal"
      ok-variant="success"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="() => onCreateWithExcel()"
    >
      <b-card-text class="text-center">
        <label for="nilai">Menambah Data Dosen Sekaligus (Banyak)</label>
        <b-form-group>
          <v-dropzone
            id="files"
            ref="files"
            :options="{
              url: BACKEND_URL,
              method: `post`,
              maxFilesize: 5,
              maxFiles: 0,
              chunking: true,
              acceptedFiles:
                `application/vnd.ms-excel,excel,spreadsheetml,.xlsx`,
              autoProcessQueue: false,
            }"
            duplicate-check
            use-custom-slot
            add-remove-links
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
            @vdropzone-complete="afterComplete"
          >
            <h6 class="mb-0">
              Masukkan File Excel
            </h6>
          </v-dropzone>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <!-- Delete dosen modal -->
    <b-modal
      id="delete-data"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="() => onDelete()"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">
          Hapus data?
        </h4>
      </b-card-text>
    </b-modal>

    <!-- New dosen modal -->
    <b-modal
      id="new-data"
      title="Tambah Dosen"
      body-class="py-2"
      size="xl"
      hide-footer
      centered
      :no-enforce-focus="true"
    >
      <b-card-text>
        <validation-observer
          ref="newDosenForm"
        >
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-col lg="6">
                <!-- Nama Dosen -->
                <b-form-group
                  label="Nama Dosen"
                  label-for="full_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Dosen"
                    vid="full_name"
                    rules="required"
                  >
                    <b-form-input
                      id="full_name"
                      v-model="form.add.full_name"
                      type="text"
                      placeholder="Nama Dosen"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Tanggal Lahir -->
                <b-form-group
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Lahir"
                    vid="tanggal_lahir"
                    rules="required"
                  >
                    <flat-pickr
                      id="tanggal_lahir"
                      v-model="form.add.tanggal_lahir"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      static="true"
                      :config="dpconfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- NIDN / NIDK -->
                <b-form-group
                  label="NIDN / NIDK"
                  label-for="nidn_or_nidk"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NIDN / NIDK"
                    vid="nidn_or_nidk"
                    rules="required"
                  >
                    <b-form-input
                      id="nidn_or_nidk"
                      v-model="form.add.nidn_or_nidk"
                      type="number"
                      placeholder="1234567890"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Gelar Akademik -->
                <b-form-group
                  label="Gelar Akademik"
                  label-for="gelar_akademik"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Gelar Akademik"
                    vid="gelar_akademik"
                  >
                    <v-select
                      id="gelar_akademik"
                      ref="select"
                      v-model="form.add.gelar_akademik"
                      :options="['Magister', 'Doktor']"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pilih"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Sarjana -->
                <b-form-group
                  label="Sarjana"
                  label-for="s_str_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sarjana"
                    vid="s_str_special"
                  >
                    <b-form-input
                      id="s_str_special"
                      v-model="form.add.s_str_special"
                      type="text"
                      placeholder="Sarjana - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Magister  -->
                <b-form-group
                  label="Magister"
                  label-for="m_mtr_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Magister"
                    vid="m_mtr_special"
                  >
                    <b-form-input
                      id="m_mtr_special"
                      v-model="form.add.m_mtr_special"
                      type="text"
                      placeholder="Magister - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Doktor -->
                <b-form-group
                  label="Doktor"
                  label-for="d_dtr_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Doktor"
                    vid="d_dtr_special"
                  >
                    <b-form-input
                      id="d_dtr_special"
                      v-model="form.add.d_dtr_special"
                      type="text"
                      placeholder="Doktor - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Bidang Keahlian -->
                <b-form-group
                  label="Bidang Keahlian"
                  label-for="bidang_keahlian"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bidang Keahlian"
                    vid="bidang_keahlian"
                  >
                    <b-form-input
                      id="bidang_keahlian"
                      v-model="form.add.bidang_keahlian"
                      type="text"
                      placeholder="Bidang Keahlian"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <!-- Sertifikat Pendidik -->
                <b-form-group
                  label="Sertifikat Pendidik"
                  label-for="sertifikat_pendidik"
                >
                  <b-form-input
                    id="sertifikat_pendidik"
                    type="text"
                    value="Iya"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Save Dosen -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="newDosenLoading"
                v-text="newDosenLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Edit dosen modal -->
    <b-modal
      id="edit-data"
      title="Ubah Dosen"
      body-class="py-2"
      size="xl"
      hide-footer
      centered
      :no-enforce-focus="true"
    >
      <b-card-text>
        <validation-observer
          ref="editDosenForm"
        >
          <b-form @submit.prevent="onUpdate">
            <b-row>
              <b-col lg="6">
                <!-- Nama Dosen -->
                <b-form-group
                  label="Nama Dosen"
                  label-for="full_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Dosen"
                    vid="full_name"
                    rules="required"
                  >
                    <b-form-input
                      id="full_name"
                      v-model="form.edit.full_name"
                      type="text"
                      placeholder="Nama Dosen"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Tanggal Lahir -->
                <b-form-group
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Lahir"
                    vid="tanggal_lahir"
                    rules="required"
                  >
                    <flat-pickr
                      id="tanggal_lahir"
                      v-model="form.edit.tanggal_lahir"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      static="true"
                      :config="dpconfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- NIDN / NIDK -->
                <b-form-group
                  label="NIDN / NIDK"
                  label-for="nidn_or_nidk"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NIDN / NIDK"
                    vid="nidn_or_nidk"
                    rules="required"
                  >
                    <b-form-input
                      id="nidn_or_nidk"
                      v-model="form.edit.nidn_or_nidk"
                      type="number"
                      placeholder="1234567890"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Gelar Akademik -->
                <b-form-group
                  label="Gelar Akademik"
                  label-for="gelar_akademik"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Gelar Akademik"
                    vid="gelar_akademik"
                  >
                    <v-select
                      id="gelar_akademik"
                      ref="select"
                      v-model="form.edit.gelar_akademik"
                      :options="['Magister', 'Doktor']"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pilih"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Sarjana -->
                <b-form-group
                  label="Sarjana"
                  label-for="s_str_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sarjana"
                    vid="s_str_special"
                  >
                    <b-form-input
                      id="s_str_special"
                      v-model="form.edit.s_str_special"
                      type="text"
                      placeholder="Sarjana - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Magister  -->
                <b-form-group
                  label="Magister"
                  label-for="m_mtr_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Magister"
                    vid="m_mtr_special"
                  >
                    <b-form-input
                      id="m_mtr_special"
                      v-model="form.edit.m_mtr_special"
                      type="text"
                      placeholder="Magister - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Doktor -->
                <b-form-group
                  label="Doktor"
                  label-for="d_dtr_special"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Doktor"
                    vid="d_dtr_special"
                  >
                    <b-form-input
                      id="d_dtr_special"
                      v-model="form.edit.d_dtr_special"
                      type="text"
                      placeholder="Doktor - Upi"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <!-- Bidang Keahlian -->
                <b-form-group
                  label="Bidang Keahlian"
                  label-for="bidang_keahlian"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bidang Keahlian"
                    vid="bidang_keahlian"
                  >
                    <b-form-input
                      id="bidang_keahlian"
                      v-model="form.edit.bidang_keahlian"
                      type="text"
                      placeholder="Bidang Keahlian"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <!-- Sertifikat Pendidik -->
                <b-form-group
                  label="Sertifikat Pendidik"
                  label-for="sertifikat_pendidik"
                >
                  <b-form-input
                    id="sertifikat_pendidik"
                    type="text"
                    value="Iya"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Save Dosen -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="editDosenLoading"
                v-text="editDosenLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'

export default {
  components: {
    flatPickr,
    Indonesian,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
    VDropzone,
  },
  data() {
    return {
      BACKEND_URL_1: process.env.VUE_APP_BACKEND_URL,
      dpconfig: {
        wrap: true,
        altInput: true,
        // dateFormat: 'Y',
        // altFormat: 'Y',
        // ariaDateFormat: 'Y',
        locale: Indonesian,
        defaultDate: 'today',
        onReady: this.flatPickrOnReady, // fix
        // onOpen : function(event){
        //   let instances = document.getElementsByClassName("flatpickr-innerContainer");
        //   for(let i= 0; i< instances.length; i+=1){
        //     instances[i].style.display="none";
        //   }
        // },
      },
      transProps: {
        name: 'flip-list',
      },
      // Form
      form: {
        add: {
          full_name: '',
          nidn_or_nidk: '',
          gelar_akademik: '',
          s_str_special: '',
          m_mtr_special: '',
          d_dtr_special: '',

          tanggal_lahir: '',
          sertifikat_pendidik: '',
          bidang_keahlian: '',
        },
        edit: {
          id: 0,
          full_name: '',
          nidn_or_nidk: '',
          gelar_akademik: '',
          s_str_special: '',
          m_mtr_special: '',
          d_dtr_special: '',

          tanggal_lahir: '',
          sertifikat_pendidik: '',
          bidang_keahlian: '',
        },
        deleteId: 0,
      },

      // Table fields, data, pagination
      fields: [
        { label: 'Nama', key: 'full_name', sortable: true },
        { label: 'NIDN / NIDK', key: 'nidn_or_nidk', sortable: true },
        { label: 'Aksi', key: 'action' },
      ],
      dosens: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,

      // loadings
      fetchLoading: true,
      newDosenLoading: false,
      editDosenLoading: false,

      // validation
      required,

      files: [],
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}/api/data_master_dosen/bulk/csv`,
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.fetch()
    },
    'pagination.selectedAmount': function () {
      this.fetch()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(keyword), 300)
      }
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch(keyword = '') {
      this.fetchLoading = true

      useJwt.http.get('data_master_dosen', {
        params: {
          page: this.pagination.currentPage - 1,
          size: this.pagination.selectedAmount,
          full_name: keyword,
          sort: 'DESC',
          state: '1',
        },
      })
        .then(response => {
          this.dosens = response.data.data_master_dosens.map((dosen, idx) => ({
            ...dosen,
            number: idx + 1,
            action: '',
          }))

          this.pagination.totalItems = response.data.total_items
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    onSubmit() {
      this.$refs.newDosenForm.validate().then(success => {
        if (success) {
          this.newDosenLoading = true

          useJwt.http.post('data_master_dosen', this.form.add)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })

              this.form.add.full_name = ''
              this.form.add.nidn_or_nidk = ''
              this.form.add.s_str_special = ''
              this.form.add.m_mtr_special = ''
              this.form.add.d_dtr_special = ''

              this.form.add.tanggal_lahir = ''
              this.form.add.sertifikat_pendidik = ''
              this.form.add.bidang_keahlian = ''

              this.$bvModal.hide('new-data')

              this.fetch()
            })
            .catch(() => this.$alert({ title: 'Gagal menambah dosen.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.newDosenLoading = false)
        }
      })
    },
    onUpdate() {
      this.$refs.editDosenForm.validate().then(success => {
        if (success) {
          this.editDosenLoading = true

          useJwt.http.put(`data_master_dosen/${this.form.edit.id}`, this.form.edit)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
              this.fetch()
              this.$bvModal.hide('edit-data')
            })
            .catch(() => this.$alert({ title: 'Gagal mengedit dosen.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.editDosenLoading = false)
        }
      })
    },
    prepareUpdate(data) {
      const {
        id,
        full_name,
        nidn_or_nidk,
        gelar_akademik,
        s_str_special,
        m_mtr_special,
        d_dtr_special,

        tanggal_lahir,
        sertifikat_pendidik,
        bidang_keahlian,
      } = data.item

      this.form.edit.id = id
      this.form.edit.full_name = full_name
      this.form.edit.nidn_or_nidk = nidn_or_nidk
      this.form.edit.gelar_akademik = gelar_akademik
      this.form.edit.s_str_special = s_str_special
      this.form.edit.m_mtr_special = m_mtr_special
      this.form.edit.d_dtr_special = d_dtr_special

      this.form.edit.tanggal_lahir = tanggal_lahir
      this.form.edit.sertifikat_pendidik = sertifikat_pendidik
      // this.form.edit.bidang_keahlian = bidang_keahlian
    },
    onDelete() {
      this.fetchLoading = true

      useJwt.http.delete(`data_master_dosen/${this.form.deleteId}/0`)
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          this.fetch()
        })
        .catch(() => this.$alert({ title: 'Gagal menghapus dosen.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    prepareDelete(data) {
      const { id } = data.item
      this.form.deleteId = id
    },
    dropzoneTemplate() {
      return `
        <div class="dz-preview dz-file-preview">
          <div class="dz-image">
            <img data-dz-thumbnail>
          </div>
          <div class="dz-details">
            <div class="dz-filename">
              <span data-dz-name></span>
            </div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-error-message">
            <span data-dz-errormessage></span>
          </div>
          <div class="dz-success-mark"></div>
          <a class="dz-remove" href="javascript:undefined;" data-dz-remove>Batal</a>
        </div>
      `
    },
    addFile(file) {
      console.log(file)
      this.files.push(file)
    },
    removeFile(removedFile) {
      this.files = this.files.filter(file => file.upload.uuid != removedFile.upload.uuid)
    },
    afterComplete(file) {
      console.log(`completd ${file}`)
    },
    onCreateWithExcel() {
      const formData = new FormData()
      const files = []
      this.files.forEach(file => {
        if (file instanceof File) {
          formData.append('file', file)
          useJwt.http.post('data_master_dosen/bulk/csv', formData)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
              this.fetch()
            })
            .catch(err => {
              console.log(`error ${err}`)
              this.$alert({ title: `${err.message}`, variant: 'danger', icon: 'XIcon' })
            })
        } else {
          files.push(file)
          console.log(`form data else : ${file}`)
        }
      })
    },
    flatPickrOnReady(_, date) {
      this.form.add.tanggal_lahir = date
      this.form.edit.tanggal_lahir = date
    },
  },
}
</script>
<style>
  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
